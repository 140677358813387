<template>
  <v-card class="pa-4 admin-report-card">
    <v-row class="report-wrap">
      <v-col
        md="4"
        sm="12"
        cols="12"
        class="d-flex align-center">
        <p class="text-h6">
          Print Order Summary
        </p>
      </v-col>
      <v-col
        md="6"
        sm="12"
        cols="12"
        class="d-flex align-center">
        <date-picker v-model="dateSelected" />
      </v-col>
      <v-col
        md="2"
        sm="12"
        cols="12"
        class="d-flex align-center">
        <v-btn
          :disabled="orderSelected.length === 0"
          color="#E1BEE7"
          class="pa-5"
          small
          @click="printOrderSummary()">
          print order <br>
          summary ({{ orderSelected.length }})
        </v-btn>
      </v-col>
      <v-col>
        <search-by-tags
          append-icon="mdi-magnify"
          tags-width="300px"
          :tags="tags"
          @on-search="searchOrder($event)" />
      </v-col>
      <v-col cols="12">
        <v-data-table
          v-model="orderSelected"
          :headers="headers"
          :items="items"
          show-select
          item-key="orderId"
          :items-per-page="options.itemsPerPage"
          :server-items-length="total"
          :options.sync="options"
          :loading="fetching"
          class="elevation-1"
          dense
          :footer-props="{'items-per-page-options': [15, 25, 50, 100]}"
          @update:options="fetchReport" />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import NewReportProvider from '@/resources/NewReportProvider'
import DatePicker from '@/views/newReport/components/DatesPicker.vue'
import SearchByTags from '@/components/SearchByTags.vue'
import ProductAttributeProvider from '@/resources/ProductAttributeProvider'

const NewReportService = new NewReportProvider()
const ProductAttributeService = new ProductAttributeProvider()
export default {
  components: { DatePicker, SearchByTags },
  data () {
    return {
      exportLoading: false,
      fetching: false,
      tags: [],
      tagsSelected: [],
      orderSelected: [],
      tagOperation: 'OR',
      options: {
        page: 1,
        itemsPerPage: 25,
        sortDesc: [true],
        sortBy: ['createdAt']
      },
      headers: [
        { text: 'Bill Id', value: 'orderId', width: 160 },
        { text: 'Create Time', value: 'createdTime', width: 150 },
        { text: 'Item Amount', value: 'itemAmount', align: 'right', width: 120, sortable: false },
        { text: 'Sub Total', value: 'sumFullPrice', align: 'right', width: 120, sortable: false },
        { text: 'Unit Discount', value: 'unitDiscount', align: 'right', width: 150, sortable: false },
        { text: 'Promotion Code', value: 'promotionCode', align: 'center', width: 150 },
        { text: 'Total', value: 'sumPrice', align: 'right', width: 120 },
        { text: 'Bill Discount', value: 'billDiscount', align: 'right', width: 120 },
        { text: 'Ship cost', value: 'shipCost', align: 'right', width: 150 },
        { text: 'Net', value: 'net', align: 'right', width: 150 },
        { text: 'Channel', value: 'channel', align: 'center', width: 150 }
      ],
      dateSelected: [this.$dayjs().subtract(6, 'day').format(), this.$dayjs().format()],
      items: [],
      total: 0
    }
  },
  mounted () {
    this.getTags()
    this.fetchReport()
  },
  methods: {
    searchOrder (attr) {
      this.options.page = 1
      this.items = []
      this.total = 0

      this.searchText = attr?.search || ''
      this.tagsSelected = attr?.tags || []
      this.tagOperation = attr?.tagOperation || 'OR'

      this.fetchReport()
    },
    printOrderSummary () {
      const bills = JSON.stringify(this.orderSelected.map((or) => or.orderId))
      window.localStorage.setItem('print-order-summay', bills)
      const routeData = this.$router.resolve({ name: 'PrintOrderSummary' })
      window.open(routeData.href, '_blank')
    },
    async getTags () {
      try {
        const { data } = await ProductAttributeService.getProductAttribute('tags', {
          page: 1,
          itemsPerPage: 9999999,
          sortBy: ['id'],
          sortDesc: [true]
        })
        this.tags = data.results.map((r) => ({
          id: r.id,
          name: r.name,
          status: 'active'
        }))
      } catch (error) {
        console.error('getTags: ', error)
        this.setErrorPage(error.code)
      }
    },
    async fetchReport () {
      try {
        this.fetching = true
        const { data } = await NewReportService.getNewReportByProductTags({
          page: this.options.page,
          limit: this.options.itemsPerPage,
          sortBy: this.options.sortBy[0],
          sortOrder: this.options.sortDesc[0] ? 'desc' : 'asc',
          tags: this.tagsSelected,
          search: this.searchText,
          tagOperation: this.tagOperation,
          startDate: this.$dayjs(this.dateSelected[0])
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format(),
          endDate: this.$dayjs(this.dateSelected[1]).add(1, 'day')
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format()
        })
        this.total = data.total
        this.items = data.results.map((re) => ({
            ...re,
            createdTime: this.$dayjs(re.createdTime).format('DD-MM-YYYY HH:mm'),
            unitDiscount: re.sumFullPrice - re.sumPrice
          }))
      } catch (error) {
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: `[error on fetch report]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.fetching = false
      }
    }
  }
}
</script>
